import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import { graphql } from 'gatsby';
import Section from "~components/Section"
import ContactForm from "~components/blocks/ContactForm"
import EnquiryForm from "~components/blocks/EnquiryForm"
import CTASection from "~components/sections/CTASection"
import resolveLink from '../utils/resolveLink'
import RichText from '~components/RichText'
import Image from '~components/Image'

const DesignServiceEnquiry = ( data ) => {

  const{ mobile } = breakpoints

  const contact = data?.data?.sanityDesignServiceEnquiry
  const contactContent = data?.data?.sanityDesignServiceEnquiry?._rawContent
  const showrooms = data?.data?.sanityShowrooms?._rawContent?.showrooms
  
  return (
    <>
      <Seo
        title={contact?.title}
        image={contact?.content?.seo?.socialImage?.asset?.url}
        description={contact?.content?.seo?.metaDescription}
        metaTitle={contact?.content?.seo?.metaTitle}
      />
      <Section
        css={css`
          padding-top: 22px;
          ${mobile}{
            padding-top: 16px;
          }
        `}
      >
        <div css={css`
          grid-column: 1/3;
          ${mobile}{
            grid-column: span 12;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: 10px;
          }
        `}>
					{contact?.title &&
					<h4 css={css`
						min-height: 88px;
						${mobile}{
							grid-column: span 12;
							min-height: 52px;
						}
					`}>
						{contact?.title}
					</h4>
					}
          {showrooms?.map(showroom => (
            <div
              key={showroom?._key}
              css={css`
                margin-bottom: 60px;
                ${mobile}{
                  grid-column: span 6;
                  margin-bottom: 58px;
                }
            `}>
              <h6 css={css`margin-bottom: 14px;`}>{showroom?.title}</h6>
              <div className="h6">{showroom?.address}</div>
              <div className="h6">{showroom?.city} {showroom?.state} {showroom?.postCode}</div>
              <div className="h6">{showroom?.country}</div>
              <div className="h6">{showroom?.phoneNumber}</div>
            </div>
          ))}
        </div> 
        <div css={css`
          grid-column: 4/10;
          ${mobile}{
            grid-column: span 12;
          }
        `}>
					<h4 css={css`min-height: 88px;${mobile}{min-height: 36px;}`}>{contact.content?.customTitle}</h4>
					<div css={css`margin-bottom: 60px;${mobile}{margin-bottom: 36px;}`}>
					<RichText content={contact?.content?.text}/>
					{data?.data?.sanityDesignServiceEnquiry?.content?.image && <Image css={css`margin-top: 1.5rem;`} asset={data?.data?.sanityDesignServiceEnquiry?.content?.image} />}
					</div>
          <EnquiryForm 
            formName={'Enquiry'}
            dropdownBackground={'var(--beige)'}
            successMessage={contact?.content?.consultationEnquirySuccessMessage}
            css={css`margin-bottom: 176px;${mobile}{margin-bottom: 108px;}`}
          />
        </div>
      </Section>
      {/* <CTASection
        title={contactContent?.cta?.title}
        image={contactContent?.cta?.image} 
				text={contactContent?.cta?.text} 
        link={contactContent?.cta?.link?.to ? resolveLink(contactContent.cta?.link?.to?._type, contactContent.cta?.link?.to?.slug?.current) : undefined}
        buttonText={contactContent?.cta?.link?.linkText}  
      /> */}
    </>
  )
}


export const query = graphql`
  query{
    sanityDesignServiceEnquiry {
      _rawContent(resolveReferences: {maxDepth: 5})
      title
      content {
				image {
					asset {
						_id
					}
				}
        seo {
          socialImage {
            asset {
              url
            }
          }
          metaDescription
          metaTitle
        }
				text: _rawTextField(resolveReferences: {maxDepth: 5})
				customTitle
        consultationEnquirySuccessMessage {
          _type
          children {
            marks
            text
            _key
            _type
          }
        }
      }
    } 
    sanityShowrooms {
      _rawContent
    }
  }
`
export default DesignServiceEnquiry
